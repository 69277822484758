import ryzLogo from '../assets/images/portfolio/RYZ/logo.jpg';
import scrabbleLogo from '../assets/images/portfolio/Scrabble/logo.png';
import fandomeLogo from '../assets/images/portfolio/Fandome/logo.png';
import MISFITLogo from '../assets/images/portfolio/MISFIT/logo.png';
import FMCLogo from '../assets/images/portfolio/FMC/logo.png';
import augzooLogo from '../assets/images/portfolio/augzoo/logo.png';
import DOSLogo from '../assets/images/portfolio/DOS/logo.png';
import BCITLogo from '../assets/images/portfolio/BCIT/logo.png';
import HulkLogo from '../assets/images/portfolio/Hulk/logo.png';
import OTHLogo from '../assets/images/portfolio/OTH/logo.png';
import BIALogo from '../assets/images/portfolio/BIA/logo.png';
import SpaceForceLogo from '../assets/images/portfolio/SpaceForce/logo.png';
import RealWorldLogo from '../assets/images/portfolio/RealWorld/logo.png';
import AfghanistanTerrainBuildLogo from '../assets/images/portfolio/AfghanistanTerrainBuild/logo.png';
import ConvoyLogo from '../assets/images/portfolio/Convoy/logo.png';
import InsightLogo from '../assets/images/portfolio/Insight/logo.png';
import QInteractiveLogo from '../assets/images/portfolio/QInteractive/logo.png';
import IronHammerLogo from '../assets/images/portfolio/IronHammer/logo.png';
import NTCLogo from '../assets/images/portfolio/NTC/logo.png';
import ISRLogo from '../assets/images/portfolio/ISR/logo.png';
import SASLogo from '../assets/images/portfolio/SAS/logo.png';
import USDALogo from '../assets/images/portfolio/USDA/logo.png';

const projectData = [
  {
    id: 3,
    title: "IKIN RYZ Holographic Display",
    year: "2023",
    developer: "HookBang, LLC, IKIN, Inc.",
    description: "Developed the overall user experience for the IKIN RYZ holographic display, focusing on intuitive UI design for core system functionalities and content creation tools for AR/VR/XR.",
    role: "Lead User Experience Designer, Front-end Developer",
    tools: "Unity3D, ARKit, C#, Photoshop",
    thumbnail: ryzLogo,
    images: [],
    videos: [],
    result: "Established a user-friendly system-wide UX by designing a highly usable and accessible interface for AR/VR/XR tools."
  },
  {
    id: 4,
    title: "Scrabble® Vision: Scorekeeper",
    year: "2023",
    developer: "HookBang, LLC, Mattel, Inc.",
    description: "Served as Lead UX Designer for the Scrabble® Vision: Scorekeeper, an AR mobile companion app developed by HookBang, LLC, in collaboration with Mattel, Inc.",
    role: "Lead UX Designer",
    tools: "Unity3D, ARKit",
    thumbnail: scrabbleLogo,
    images: [],
    videos: [],
    result: "Successfully increased accessibility and player engagement by creating intuitive onboarding and interaction systems."
  },
  {
    id: 7,
    title: "MISFIT: Misinformation Intelligence Test Bed",
    year: "2020-2021",
    developer: "HookBang, LLC, Advanced Brain Monitoring, Inc., DARPA",
    description: "Developed a custom Minecraft® level integrated with neuro hardware to analyze user responses to disinformation.",
    role: "Game Developer, Researcher",
    tools: "Minecraft, React.js, Neuro hardware integration",
    thumbnail: MISFITLogo,
    images: [],
    videos: [],
    result: "Successfully collected critical data on user responses to disinformation, supporting efforts to combat misinformation."
  },
  {
    id: 8,
    title: "DC FanDome 2020",
    year: "2020",
    developer: "HookBang, LLC, Double A Labs, Warner Bros. Interactive Entertainment",
    description: "Worked as a Unity WebGL Engineer for DC FanDome 2020, managing the content pipeline and optimizing performance for a browser-based first-person virtual experience.",
    role: "Unity WebGL Engineer",
    tools: "Unity WebGL, C#, 3DS Max, Maya, Photoshop",
    thumbnail: fandomeLogo,
    images: [],
    videos: [],
    result: "Successfully optimized performance and content delivery for millions of participants during the DC FanDome event."
  },
  {
    id: 6,
    title: "Augzoo: Location-Based Augmented Reality Platform",
    year: "2016-2023",
    developer: "Augzoo, LLC",
    description: "Co-founded and served as Lead Developer and UX Designer for Augzoo, a location-based augmented reality (AR) platform.",
    role: "Co-founder, User Experience Designer, Interaction Designer, Developer",
    tools: "Unity3D, ARCore, ARKit, Photoshop, 3DS Max",
    thumbnail: augzooLogo,
    images: ["https://johnnyluce.com/images/Portfolio/Projects/augzoo/content/editor3d.PNG"],
    videos: [],
    result: "Significantly enhanced user engagement with AR content, contributing to the platform’s long-term success."
  },
  {
    id: 1,
    title: "Department of State Consular Affairs Online Services",
    year: "2024",
    developer: "Fearless Tech, Department of State, GSA Centers of Excellence",
    description: "As a User Experience Researcher and Customer Experience Designer, led research for the Department of State Consular Affairs, focusing on improving user experiences for key services, including Lost and Stolen Passport reporting, Online Passport Renewal, and the National Passport Information Center chat.",
    role: "User Experience Researcher and Customer Experience Designer",
    tools: "Figma, Axure, UserTesting",
    thumbnail: DOSLogo,
    images: [],
    videos: [],
    result: "Successfully designed user-centered improvements that increased satisfaction and ease of use for the Department of State’s online services."
  },
  {
    id: 2,
    title: "Federal Maritime Commission Website Redesign",
    year: "2024",
    developer: "Fearless Tech, Federal Maritime Commission, GSA Centers of Excellence",
    description: "Led User Experience research and content management for a redesign project of the Federal Maritime Commission website, focusing on user-centered design to optimize usability for maritime companies, legal entities, and the general public.",
    role: "Lead User Experience Researcher and Content Manager",
    tools: "Figma, HTML/CSS, JavaScript",
    thumbnail: FMCLogo,
    images: [],
    videos: [],
    result: "Delivered a more user-friendly and accessible website, significantly improving ease of use for diverse user groups."
  },
  {
    id: 22,
    title: "USDA Loan Assistance Tool",
    year: "2022",
    developer: "Fearless Tech, USDA, GSA Centers of Excellence",
    description: "Led user experience research for the USDA Loan Assistance Tool (LAT), an online quiz that helps users check their eligibility for various USDA farm loan programs. Collaborated with designers and conducted UX research to optimize the tool’s usability. Additionally, I coded a prototype using HTML, CSS, and JavaScript for testing purposes.",
    role: "User Experience Researcher, Front-end Developer",
    tools: "Figma, UserTesting, HTML, CSS, JavaScript",
    thumbnail: USDALogo,
    images: [],
    videos: [],
    result: "My prototype and code were used in the final production site, which is live today. The tool improved the user experience for farmers by streamlining the loan eligibility process and ensuring ease of use."
  },
  {
    id: 5,
    title: "City of Baltimore Website User Experience Research",
    year: "2023",
    developer: "Fearless Tech",
    description: "Led user experience research for the City of Baltimore’s website, focusing on improving accessibility and optimizing the information architecture.",
    role: "User Experience Researcher",
    tools: "Figma, UserTesting",
    thumbnail: BCITLogo,
    images: [],
    videos: [],
    result: "Improved website accessibility and user flow, making it easier for residents and visitors to navigate and find information."
  },
  {
    id: 9,
    title: "Q-Interactive",
    year: "2015-2016",
    developer: "Pearson Clinical Assessment",
    description: "Served as a User Experience Researcher for Pearson Clinical Assessment’s Q-Interactive platform, identifying usability issues and proposing design improvements.",
    role: "User Experience Researcher",
    tools: "Axure, Photoshop",
    thumbnail: QInteractiveLogo,
    images: [],
    videos: [],
    result: "Improved user interaction and usability for the Q-Interactive platform, leading to increased user satisfaction."
  },
  {
    id: 23,
    title: "SpaceForce",
    year: "2013",
    developer: "Intific, Inc., DARPA, UCLA CRESST",
    description: "Worked as a Game Developer for SpaceForce, an adaptive learning game developed in collaboration with Intific, Inc., DARPA, and UCLA CRESST. Using tools such as HTML5, CSS, JavaScript, Axure, and Photoshop, designed and implemented an interactive game aimed at teaching physics concepts to K-3 students. The game adapted to the individual learning pace of each student, making complex physics concepts accessible and engaging for young learners.",
    role: "Game Developer",
    tools: "HTML5, CSS, JavaScript, Axure, Photoshop",
    thumbnail: SpaceForceLogo,
    images: [],
    videos: [],
    result: "Successfully developed an engaging, adaptive learning game that made physics education fun and interactive for K-3 students. The game’s tailored learning approach helped foster a deeper understanding of physics fundamentals while maintaining high levels of student engagement."
  },
  {
    id: 11,
    title: "Social Action Storm",
    year: "2013",
    developer: "Intific, Inc., DARPA",
    description: "Designed and developed an app for the Social Action Storm project, tracking and analyzing user behavior in a social media game environment.",
    role: "Interaction Designer",
    tools: "HTML, CSS, JavaScript, Axure, Photoshop",
    thumbnail: SASLogo,
    images: [],
    videos: [],
    result: "Developed a comprehensive platform that tracked user interactions and provided insights into the spread of misinformation."
  },
  {
    id: 12,
    title: "Insight",
    year: "2013",
    developer: "Intific, Inc., DARPA",
    description: "Developed a virtual testbed to test sensor fusion intelligence, surveillance, and reconnaissance (ISR) capabilities for a constellation of UAVs.",
    role: "Lead Developer",
    tools: "ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: InsightLogo,
    images: [],
    videos: [],
    result: "Successfully developed a virtual testbed that enabled testing and analysis of UAV sensor fusion in real-time ISR scenarios."
  },
  {
    id: 13,
    title: "IronHammer",
    year: "2012",
    developer: "Intific, Inc., DARPA",
    description: "Led the development of the IronHammer toolset, enhancing DARPA’s RealWorld platform by adding 3D representations of geographic elements.",
    role: "User Experience Researcher, Designer",
    tools: "ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: IronHammerLogo,
    images: [],
    videos: [],
    result: "Successfully developed a robust toolset that significantly improved RealWorld’s capability to visualize complex geographic and structural data."
  },
  {
    id: 14,
    title: "Intelligence, Surveillance, and Reconnaissance (ISR) Testbed",
    year: "2012",
    developer: "Intific, Inc., DARPA",
    description: "Led the design and development of a sophisticated application that integrated geospatial data tools with advanced 3D modeling.",
    role: "Lead Developer",
    tools: "XML, ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: ISRLogo,
    images: [],
    videos: [],
    result: "Delivered a powerful tool that enabled enhanced intelligence gathering, operational planning, and mission rehearsals."
  },
  {
    id: 15,
    title: "DARPA RealWorld: Operational Rehearsal Convoy",
    year: "2011",
    developer: "Intific, Inc., DARPA",
    description: "Developed mission planning and rehearsal toolkits for the Department of Defense, using real-time geospatial data and 3D models to simulate convoy operations.",
    role: "Lead Developer",
    tools: "ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: ConvoyLogo,
    images: [],
    videos: [],
    result: "Delivered critical tools that significantly enhanced military mission planning and rehearsal capabilities."
  },
  {
    id: 16,
    title: "National Training Center: ISR Testbed",
    year: "2010",
    developer: "Intific, Inc., DARPA",
    description: "Built an interactive virtual model of Fort Irwin using GIS data, allowing military personnel to rehearse and plan missions in a simulated environment.",
    role: "Lead Developer",
    tools: "ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: NTCLogo,
    images: [],
    videos: [],
    result: "Improved training and rehearsal capabilities for military operations, enhancing mission readiness."
  },
  {
    id: 17,
    title: "Afghanistan Terrain Build",
    year: "2009",
    developer: "Intific, Inc., DARPA",
    description: "Developed a highly detailed virtual model of Afghanistan using GIS data to support mission planning and rehearsal for military personnel.",
    role: "Lead Developer",
    tools: "ArcGIS, GlobalMapper, Photoshop, 3DS Max, Axure, C#",
    thumbnail: AfghanistanTerrainBuildLogo,
    images: [],
    videos: [],
    result: "Enhanced mission planning and training capabilities by offering a comprehensive virtual representation of the terrain."
  },
  {
    id: 18,
    title: "DARPA RealWorld",
    year: "2009, 2011, 2012",
    developer: "Intific, Inc., DARPA",
    description: "Developed mission planning and rehearsal toolkits using real-time data and 3D visualization tools, allowing military personnel to visualize, plan, and rehearse missions.",
    role: "Lead Developer",
    tools: "Photoshop, 3DS Max, Axure, C#",
    thumbnail: RealWorldLogo,
    images: [],
    videos: [],
    result: "Designed mission planning and rehearsal toolkits that significantly improved military operational efficiency."
  },
  {
    id: 19,
    title: "The Incredible Hulk",
    year: "2008",
    developer: "Edge of Reality, Sega",
    description: "Worked as a Level Designer for The Incredible Hulk, developing immersive and interactive levels across multiple platforms.",
    role: "Level Designer",
    tools: "Proprietary Game Engine, Maya, 3DS Max, Photoshop",
    thumbnail: HulkLogo,
    images: [],
    videos: [],
    result: "Designed engaging levels that contributed to the overall success of the game."
  },
  {
    id: 20,
    title: "Over the Hedge",
    year: "2006",
    developer: "Edge of Reality, Activision",
    description: "Worked as a Level Designer for Over the Hedge, an action-adventure game based on the animated film, across multiple platforms.",
    role: "Level Designer",
    tools: "Proprietary Game Engine, Maya, 3DS Max, Photoshop",
    thumbnail: OTHLogo,
    images: [],
    videos: [],
    result: "Created fun and interactive levels, contributing to the game's success and recognition."
  },
  {
    id: 21,
    title: "Brothers in Arms: Earned in Blood",
    year: "2005",
    developer: "Gearbox Software, Ubisoft",
    description: "Worked as a Level Designer for Brothers in Arms: Earned in Blood, designing multiplayer levels that emphasized tactical gameplay.",
    role: "Level Designer",
    tools: "Unreal Engine, 3DS Max, Photoshop",
    thumbnail: BIALogo,
    images: [],
    videos: [],
    result: "Designed challenging and immersive multiplayer levels that contributed to the game’s critical acclaim."
  }
];

export default projectData;
