import React from 'react';

const Contact = () => {
  return (
    <div className="contact-details">
        <section className="professional-statement">
            <h3>Contact Me</h3>
            <p>Phone: (512) 825-6833 </p>
            <p>Email: <a href="mailto:johnnyluce@gmail.com">johnnyluce@gmail.com</a></p>
            <p>LinkedIn: <a href="https://linkedin.com/in/johnnyluce/" target="_blank" rel="noopener noreferrer">linkedin.com/in/johnnyluce/</a></p>
        </section>
    </div>
  );
};

export default Contact;