import React, { useState } from "react";
import projectData from "../data/projectData"; 
import { portfolioContent } from '../data/copy.js';

const Portfolio = () => {
    const [showAll, setShowAll] = useState(false);
    const [expandedProject, setExpandedProject] = useState(null);
  
    const visibleProjects = showAll ? projectData : projectData.slice(0, 3);
  
    const handleShowAll = () => {
      setShowAll(!showAll);
    };
  
    const handleExpandProject = (id) => {
      setExpandedProject(expandedProject === id ? null : id);
    };
  
    return (
      <div className="portfolio-container">
        <h3>{portfolioContent.title}</h3>
        <p>{portfolioContent.paragraph1} <a href="mailto:johnnyluce@gmail.com">johnnyluce@gmail.com</a></p>
        <div className="project-grid">
          {visibleProjects.map((project) => (
            <div key={project.id} className={`project-card ${expandedProject === project.id ? 'expanded' : ''}`}>
              <img src={project.thumbnail} alt={project.title} />
              <div className="project-info">
                <h3>{project.title}</h3>
              </div>
  
              {/* Expanded view */}
              <div className="project-details">
                <p><strong>Role:</strong> {project.role}</p>
                <p><strong>Tools Used:</strong> {project.tools}</p>
                <p><strong>Description:</strong> {project.description}</p>
                <p><strong>Result:</strong> {project.result}</p>
                <p><strong>Client:</strong> {project.developer}</p>
                <p><strong>Released:</strong> {project.year}</p>
  
                {/* Display images if available */}
                {project.images.length > 0 && (
                  <div className="project-images">
                    {project.images.map((image, index) => (
                      <img key={index} src={image} alt={`Project ${project.title} Image ${index + 1}`} />
                    ))}
                  </div>
                )}
  
                {/* Display videos if available */}
                {project.videos.length > 0 && (
                  <div className="project-videos">
                    {project.videos.map((video, index) => (
                      <video key={index} width="100%" controls>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ))}
                  </div>
                )}
              </div>
  
              {/* Show/Hide Details button */}
              <button
                className="expand-btn"
                onClick={() => handleExpandProject(project.id)}
              >
                {expandedProject === project.id ? "Hide Details" : "Show Details"}
              </button>
            </div>
          ))}
        </div>
  
        <button className="show-all-btn" onClick={handleShowAll}>
          {showAll ? "Show Less" : "Show All Projects"}
        </button>
      </div>
    );
  };
  
  export default Portfolio;