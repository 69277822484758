export const headerContent = {
  title: 'Creative Technology and Experience Design Strategist',
  subtitle: 'Providing strategic leadership and hands-on practices to ensure success of development projects.',
};

export const ctaContent = {
  tagline: 'Your Strategic Partner for Vendor Management and Project Oversight',
  paragraph: 'With over two decades of experience across industries including government, healthcare, gaming, and defense, I provide expert oversight to web, app, and software development projects. My focus is on ensuring clarity in vendor agreements, managing deadlines, maintaining accessibility standards, and delivering solutions aligned with industry best practices. Whether overseeing large-scale government websites or developing immersive digital experiences, my mission is to guide projects toward successful, innovative outcomes.',
  buttonText: 'Get Your Free Consultation',
};

export const servicesContent = {
  title: 'Services',
  
  service1Title: 'Contract Review and Negotiation',
  service1SubTitle: 'Ensuring Fair, Transparent, and Cost-Effective Agreements',
  service1Description: 'I offer expert contract review and negotiation services, ensuring vendor agreements are clear, aligned with project goals, and cost-effective. Whether it\'s a government contract or a healthcare platform, I ensure terms are fair, deliverables are well-defined, and accessibility is integrated from the start.',
  
  service2Title: 'Project Oversight',
  service2SubTitle: 'Guiding Projects to Successful Completion',
  service2Description: 'I oversee the entire project lifecycle, ensuring that development efforts meet timelines, stay within budget, and maintain the highest quality and accessibility standards. From healthcare tools to gaming platforms, I ensure seamless coordination and accountability at every stage.',
  
  service3Title: 'Vendor Accountability',
  service3SubTitle: 'Holding Vendors to Their Commitments',
  service3Description: 'I advocate for businesses by holding vendors accountable for delivering on their promises. With experience across sectors like government, gaming, and healthcare, I ensure transparency, open communication, and timely delivery of high-quality, accessible solutions.',
  
  service4Title: 'UX Research and Design',
  service4SubTitle: 'Creating Intuitive, User-Centered Digital Experiences',
  service4Description: 'I specialize in UX research and design, delivering intuitive and accessible user experiences. From prototyping healthcare tools to developing immersive gaming experiences, my user-centered approach ensures interfaces are functional, engaging, and meet the highest accessibility standards.',
};

export const aboutContent = {
  title: 'My Story',
  paragraph1: 'I began my career in interactive media design and have since expanded my expertise across various industries. My journey has taken me through video game design, government defense projects, healthcare platforms, and beyond—all while maintaining a focus on user-centered, innovative solutions that leverage cutting-edge technology to solve real-world problems.',

  paragraph2: 'In the gaming industry, I started as a Level Designer for Gearbox Software’s *Brothers in Arms: Earned in Blood*. At Edge of Reality, I contributed to AAA titles like *The Incredible Hulk* and *Over the Hedge*, where I honed my skills in real-time 3D graphics, gameplay design, and level creation for interactive experiences. *Over the Hedge* was nominated for the *2007 IAA Children’s Game of the Year*, a recognition of the game’s family-friendly design and immersive world-building. My work on these titles gave me invaluable experience in balancing creative vision with technical precision across multiple platforms.',

  paragraph3: 'In 2008, I transitioned to the Government and Defense sector, joining Intific, Inc., where I led the design and prototyping of mission-critical applications for DARPA and other government agencies. I developed complex simulations, decision-making tools, and virtual environments aimed at enhancing mission planning and training for military personnel. My work on SpaceForce, an HTML5-based adaptive learning game for K-3 students developed for DARPA and UCLA CRESST, demonstrated my ability to create secure, high-impact educational solutions within the constraints of government projects. This project helped young learners engage with complex physics concepts in a fun and interactive way, setting the stage for my continued involvement in educational technologies. I was also instrumental in developing various testbeds for cognitive load research, including IronHammer and Social Action Storm, which contributed to broader studies on decision-making in high-stakes environments.',

  paragraph4: 'In the Healthcare and Scientific Research sectors, I’ve developed a variety of tools aimed at improving mental health and clinical outcomes. At Pearson Clinical, I contributed to the development of their Q-Interactive platform, where I conducted extensive UX research to refine clinical assessments used by professionals in educational and psychological testing environments. My role extended into mobile health applications, where I designed apps for behavioral health that integrated Positive Psychology interventions. I have also been involved in cognitive load research, working with institutions like the University of Texas to design studies that further our understanding of human behavior in information-rich environments.',

  paragraph5: 'In my work on Commercial Applications, I’ve delivered solutions across a broad spectrum of industries. My recent work at Fearless Tech involved modernizing large-scale government systems, focusing on user-centered design, accessibility improvements, and enhanced user experiences. This included major projects such as the redesign of the Federal Maritime Commission’s digital platform and conducting UX research for the Department of State’s Consular Affairs online services. I also contributed to the design of AR platforms for immersive experiences and interactive digital content. During my time at HookBang, I developed content pipelines and user interfaces for AR/VR/XR experiences, including projects like the IKIN RYZ holographic display, where I focused on creating seamless onboarding and user interaction systems for cutting-edge hardware platforms.',

  paragraph6: 'In addition to my professional work, I have a deep commitment to education and mentorship. I have taught game design and interactive media courses at Austin Community College and DeVry University, where I specialized in AR/VR technologies and the design principles behind immersive, interactive experiences. Through these roles, I have had the opportunity to share my passion for emerging technologies with students, helping to shape the next generation of designers and developers.',

  paragraph7: 'My career has allowed me to move fluidly between sectors, adapting to the unique challenges and opportunities in each one. From the gaming industry to healthcare, government defense, and commercial applications, I have consistently delivered user-centered, innovative solutions. My experience working on AR/VR and emerging technologies, combined with my commitment to education, positions me at the forefront of interactive media and user experience design. I look forward to continuing to push the boundaries of what’s possible, creating experiences that are not only functional but transformative.',
};

export const contactFormContent = {
  successTitle: 'Thank you for reaching out! I will get back to you shortly.',
};

export const professionalStateentContent = {
  title: 'Professional Summary',
  paragraph1: 'Dynamic professional with 20+ years of experience in UX design, AR/VR, software development, video games, neuroscience research, and project management. Proven ability to lead cross-functional teams in delivering innovative, human-centered solutions across government, defense, education, and commercial sectors.',
  paragraph2: 'Skilled in overseeing the full project lifecycle using agile methodologies, with expertise in AR/VR development, UX/UI research, game design, and data visualization. Known for blending creativity and data-driven insights to create immersive experiences and impactful software products. Experienced in integrating neuro hardware and behavioral analytics into interactive projects.',
  paragraph3: 'As an educator in game design and interactive media, I have designed and delivered university-level curricula, making complex concepts accessible. His leadership spans diverse projects, including co-founding Augzoo, an AR platform, showcasing his entrepreneurial drive and expertise in emerging technologies.',
};

export const portfolioContent = {
  title: 'Published Works and Projects',
  paragraph1: 'These cards showcase several published works and projects spanning my career. Out of respect for previous clients and, in some cases, legal agreements, I do not publicly share work samples. If you\'re interested in learning more about my contributions to a specific project, I will gladly share additional details upon request.',
};
