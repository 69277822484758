import React from 'react';
import { professionalStateentContent } from '../data/copy.js';

const ProfessionalStateent = () => {
  return (
    <section className="professional-statement">
      <div className='professional-statement-resume'>
        <p>(512) 825-6833 | <a href="mailto:johnnyluce@gmail.com">johnnyluce@gmail.com</a> | <a href="https://linkedin.com/in/johnnyluce/" target="_blank" rel="noopener noreferrer">linkedin.com/in/johnnyluce/</a> | <a href="https://docs.google.com/document/d/1yx-uff_af6kZlnMHMT1V4IHJYwwIce-VnZuwdkmAotQ/edit?tab=t.0">Resume</a> | <a href="https://docs.google.com/document/d/1WSSAEWAvUHTQOAOP5bkugNOo2J7isVKeyddpGSDUhrw/edit?usp=sharing">Curriculum Vitae</a></p>
      </div>
      <h3>{professionalStateentContent.title}</h3>
      <p>{professionalStateentContent.paragraph1}</p>
      <p>{professionalStateentContent.paragraph2}</p>
      <p>{professionalStateentContent.paragraph3}</p>
    </section>
  );
};

export default ProfessionalStateent;
